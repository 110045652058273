@media (width >= 768px) {
  footer {
    padding: var(--size-gap-2x) var(--size-gap-4x) !important;
  }
}

@media (width >= 990px) {
  footer {
    padding: var(--size-gap) !important;
    padding-top: var(--size-gap-4x) !important;
    text-align: left !important;
    grid-template-columns: 1fr 3fr !important;
  }

  .copyright {
    grid-column: 1 / span 2;
    font-size: 16px !important;
  }
}

.FooterLinks > a, .FooterLinks > div {
  color: var(--color-desaturated-white-text);
  font-size: 16px;
  font-family: var(--font-highlight);
  text-transform: uppercase;
  text-decoration: none;
  transition: all .1s;
}

.FooterLinks > a:hover {
  opacity: 1;
  color: var(--color-offwhite);
}

@media (width >= 990px) {
  .FooterLinks {
    justify-content: start !important;
  }

  .FooterLinks > a, .FooterLinks > div {
    margin-right: calc(var(--size-gap-2x)  + 5px);
    font-size: 14px !important;
  }
}

@media (width >= 990px) {
  .Header .HeaderShop {
    font-size: 16px !important;
  }

  .Header .HeaderShop > img {
    height: 24px !important;
  }
}

.IndexHero:after {
  opacity: .5;
  z-index: 0;
  background: var(--color-brand-primary);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 990px) {
  .IndexHero {
    padding: var(--size-gap);
    align-items: start;
    height: 100vh !important;
  }
}

.IndexHero video {
  height: 180vw;
}

@media (width >= 990px) {
  .IndexHero video {
    height: 100vh;
  }
}

.IndexHero .IndexHeroShop {
  right: -10px;
  bottom: var(--size-gap);
}

.IndexHero .IndexHeroShop > div {
  flex-direction: column;
  align-items: center;
  width: 140px;
  display: flex;
}

@media (width >= 990px) {
  .IndexHero .IndexHeroShop {
    right: var(--size-gap-2x);
    bottom: var(--size-gap-2x);
  }

  .IndexHero .IndexHeroShop > div {
    width: 200px;
  }
}

/*# sourceMappingURL=Index.815cfabf.css.map */
