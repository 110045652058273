.Header {
	@media (min-width: 990px) {
		.HeaderShop {
			font-size: 16px !important;
		}
		.HeaderShop > img {
			height: 24px !important;
		}
	}
}
