@media (min-width: 768px) {
	footer {
		padding: var(--size-gap-2x) var(--size-gap-4x) !important;
	}
}

@media (min-width: 990px) {
	footer {
		grid-template-columns: 1fr 3fr !important;
		padding: var(--size-gap) !important;
		padding-top: var(--size-gap-4x) !important;
		text-align: left !important;
	}

	.copyright {
		grid-column: 1 / span 2;
		font-size: 16px !important;
	}
}
