.FooterLinks > a,
.FooterLinks > div {
	transition: all 0.1s ease;
	color: var(--color-desaturated-white-text);
	font-size: 12px;
	font-size: 16px;
	font-family: var(--font-highlight);
	text-decoration: none;
	text-transform: uppercase;
}

.FooterLinks > a:hover {
	opacity: 1;
	color: var(--color-offwhite);
}

@media (min-width: 990px) {
	.FooterLinks {
		justify-content: start !important;
	}

	.FooterLinks > a,
	.FooterLinks > div {
		margin-right: calc(var(--size-gap-2x) + 5px);
		font-size: 14px !important;
	}
}
