.IndexHero {
	&:after {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0.5;
		z-index: 0;
		background: var(--color-brand-primary);
		width: 100%;
		height: 100%;
		content: '';
	}
	@media (min-width: 990px) {
		align-items: start;
		padding: var(--size-gap);
		height: 100vh !important;
	}

	video {
		height: 180vw;
	}

	@media (min-width: 990px) {
		video {
			height: 100vh;
		}
	}

	.IndexHeroShop {
		right: -10px;
		bottom: var(--size-gap);
	}
	.IndexHeroShop > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 140px;
	}
	@media (min-width: 990px) {
		.IndexHeroShop {
			right: var(--size-gap-2x);
			bottom: var(--size-gap-2x);
		}
		.IndexHeroShop > div {
			width: 200px;
		}
	}
}
